<template>
  <b-alert v-show="version_update" show variant="warning">有新的系統版本! 5秒後將重新載入</b-alert>
  <CToaster placement="top-end" visible>
    <CToast v-for="(toast, index) in toasts">
      <CToastHeader closeButton>
      <span class="me-auto fw-bold">{{toast.title}}</span>
      </CToastHeader>
    </CToast>
  </CToaster>
  <!-- Modal for Authorization-->
  <div>
    <b-button
      id="confirmModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#confirmModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            style="display: none"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>{{ $t('common.master.pass_code') }}</span>
              <CFormInput
                id="staff_code"
                v-model="staff_code"
                size="sm"
                type="password"
                @keyup.enter="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="!vaild_staff_code && !staff_id && allowed" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="!vaild_staff_code && staff_id">
            <div class="row">
              <div class="col-12">
                <span class="text-danger">{{
                  $t('common.message.permission_denied')
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="!vaild_staff_code && !allowed" class="row">
            <div class="col-12">
              <h4 style="color: red;">請使用公司網絡!!!</h4>
            </div>
          </div>
          <div class="row" v-if="staff_code_checked == false">
            <div class="col-12">
              <!--
              <b-button
                variant="secondary"
                href="./#/Dashboard"
                @click="home_page()"
              >
                {{ $t('common.button.home') }}
              </b-button>
              -->
              輸入後按Enter/提交繼續
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="staff_code_check()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
        </div>
      </div>
    </div>
  </div>
  <!-- End Authorization-->

  <b-overlay :show="show" rounded="sm">
    <div class="row">
      <div class="col-4 text-left">
        <span v-if="staff_nickname">Hello, {{ staff_nickname }}</span>
      </div>
      <div class="col-4">
        <div class="flex-row justify-content-between">
          <div>
            <span style="color:green;">預約表</span>
          </div>
        </div>
      </div>
      <div class="col-4 text-right">
        <span style="font-size: 13px;">Version {{ version }}&nbsp;</span>
      </div>
    </div>

    <div class="row">
      <div class="col-4 text-left">
        <span>{{ today_date }} (星期{{date_day}})</span>
      </div>
      <div class="col-4 text-center">
        <span>會員覆查表</span>
      </div>
      <div class="col-2 text-right">
      </div>
      <div class="col-2 text-right">
        <b-button
          class="mx-1 position-relative"
          size="sm"
          variant="outline-warning"
          @click="get_api()"
          >
            <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
            <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
              <span style="color: red; font-size: 1rem !important;">!</span>
            </b-badge>
        </b-button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-3">
        <div v-show="!advisor_admin_role">
          <b-tabs
            active-nav-item-class="text-success"
          >
              <b-tab active @click="filtersourceList.sourceList = 1">
                <template #title>
                  <strong>{{ branch }} ({{ get_dataSourceList(1).length }})</strong>
                </template>
              </b-tab>
          </b-tabs>
        </div>
        <div v-show="advisor_admin_role">
          <b-tabs
            active-nav-item-class="text-success"
          >
            <b-tab active @click="filtersourceList.sourceList = 2">
              <template #title>
                <strong>TST</strong>
              </template>
            </b-tab>
            <b-tab @click="filtersourceList.sourceList = 3">
              <template #title>
                <strong>CWB</strong>
              </template>
            </b-tab>
            <b-tab @click="filtersourceList.sourceList = 4">
              <template #title>
                <strong>ST</strong>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="col-3 text-right">
          <Multiselect
            v-if="!role_check(3) && !role_check(32)"
            v-model="filtersourceList.booking_date"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :create-option="false"
            :options="date_list"
            :allow-empty="false"
            :canClear="true"
            valueProp="id"
            trackBy="id"
            label="id"
          />
      </div>
      <div class="col-2 text-right">
          <Multiselect
            v-if="!role_check(3) && !role_check(32)"
            v-model="filtersourceList.advisor"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :create-option="false"
            :options="advisor_list"
            :allow-empty="false"
            :canClear="true"
            valueProp="id"
            trackBy="nickname"
            label="nickname"
            placeholder="選擇顧問"
          />
      </div>
      <div class="col-2 text-right">
        <CFormInput
          v-model="filtersourceList.freetext"
          type="text"
          placeholder="搜尋會員編號"
        />
      </div>
      <div class="col-2 text-right">
        <Multiselect
          v-if="!role_check(32)"
          v-model="filtersourceList.checkin"
          mode="single"
          :close-on-select="true"
          :searchable="false"
          :create-option="false"
          :options="checkin_list"
          :allow-empty="false"
          :canClear="false"
          valueProp="id"
          trackBy="name"
          label="name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table-lite
          :is-slot-mode="true"
          :columns="getField"
          :rows="dataSourceList"
          :is-hide-paging="true"
        >
          <template #member="data">
            <div v-if="data.value.member_no" :class="data.value.gender == 'M' ? 'genderM genderMBG' : 'genderF genderFBG'">
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''">{{ data.value.member_no.toUpperCase() }}&nbsp;</span><br>
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''" v-show="data.value.nickname">({{ data.value.nickname }})&nbsp;</span>
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''" v-show="!data.value.nickname">({{ data.value.name }})&nbsp;</span>
              <span v-show="data.value.specify == '1'"><font-awesome-icon :icon="['fas', 'star']" size="lg"/></span>
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''" v-show="data.value.new_join == '1'"> (新會員)</span>
            </div>
            <div v-else-if="data.value.special_event > 0">
              <span>{{ getlistitem('special_event', data.value.special_event) }}</span>
              <span v-show="data.value.remark">-{{data.value.remark}}</span>
            </div>
          </template>
          <template #booking_time="data">
            {{ data.value.booking_date }}<br>
            ({{ data.value.start_time }}-{{ data.value.end_time }})<br>
            <span v-if="data.value.checkin_time">Check-In: {{ data.value.checkin_time }}</span>
            <span v-else-if="data.value.arrival_time && !data.value.checkin_time && (today_date > data.value.bookingDate || getTimeDiff(data.value.arrival_time, '15'))" class="without_therapist">
              未Check In
            </span>
          </template>
          <template #arrival="data">
            <span v-if="data.value.arrival_time">{{ data.value.arrival_time }}</span>
            <span v-else :class="currentTime > data.value.start_time ? 'without_therapist' : ''">未到達</span>
          </template>
          <template #therapist="data">
            {{ getlistitem('therapist', data.value.therapist) }}
          </template>
          <template #new_number="data">
            {{ data.value.new_number_show }} / {{ data.value.new_number }}
          </template>
          <template #old_number="data">
            {{ data.value.old_number_show }} / {{ data.value.old_number }}
          </template>
          <template #total_number="data">
            {{ data.value.total_number_show }} / {{ data.value.total_number }}
          </template>
          <template #advisor="data">
            <span>{{ getlistitem('advisor', data.value.advisor) }}</span>
          </template>
          <template #remark="data">
            {{ data.value.remark }}
          </template>
          <template #todo="data">
            <div class="row">
              <div class="col-4 text-center"></div>
              <div class="col-4 text-center">
                <b-form-checkbox
                  v-if="!role_check(3)"
                  v-model="data.value.todo"
                  value="1"
                  unchecked-value="0"
                  @change="inline_edit(data.value)"
                >
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="role_check(3)"
                  v-model="data.value.advisorTodo"
                  value="1"
                  unchecked-value="0"
                  @change="inline_edit(data.value)"
                >
                </b-form-checkbox>
              </div>
              <div class="col-4 text-center"></div>
            </div>
          </template>
          <template #todo_remark="data">
            <b-form-textarea
              v-if="!role_check(3)"
              v-model="data.value.todo_remark"
              rows="3"
              @input="inline_edit(data.value)"
            ></b-form-textarea>
            <b-form-textarea
              v-if="role_check(3)"
              v-model="data.value.advisorTodoRemark"
              rows="3"
              @input="inline_edit(data.value)"
            ></b-form-textarea>
          </template>
          <template #room="data">
            <div :class="data.value.without_therapist == '1' ? 'without_therapist' : ''">
              <span>{{ getlistitem('room', data.value.room) }}</span>
            </div>
          </template>
          <template #checkin="data">
            <span v-if="data.value.checkin_time">{{ data.value.checkin_time }}</span>
            <span v-else-if="data.value.arrival_time && !data.value.checkin_time && (today_date > data.value.bookingDate || getTimeDiff(data.value.arrival_time, '15'))" class="without_therapist">
              未Check In
            </span>
          </template>
          <template #checkout="data">
            <span v-if="data.value.checkout_time">{{ data.value.checkout_time }}</span>
            <span v-else-if="data.value.checkin_date && !data.value.checkout_date && (today_date > data.value.bookingDate || currentTime > data.value.end_time)" class="without_therapist">
              超過預計結束時間
            </span>
          </template>
          <template #cancel_reason="data">
            <span>{{ getlistitem('cancel_reason', data.value.cancel_reason) }}</span>
            <span v-show="data.value.cancel_reason_remark">-{{ data.value.cancel_reason_remark }}</span>
          </template>
          <template #created_from="data">
            <span v-if="data.value.created_from == '1'">分店</span>
            <span v-if="data.value.created_from != '1'">{{ getlistitem('staff', data.value.created_by) }}</span>
          </template>
          <template #action="data">
            <b-button v-if="!role_check(32) && data.value.datachange" variant="success" @click="action_auth('submit', data.value)">
              <span>{{ $t('common.button.update') }}</span>
            </b-button>
          </template>
        </table-lite>
      </div>
    </div>
  </b-overlay>
</template>

<script>
export default {
  components: {
  },
  name: 'BookingList',
  data() {
    return {
      allowed: false,
      modalShow: false,
      need_update: false,
      version: '',
      version_update: false,
      branch_id: '',
      advisor: '',
      staff: '',
      staff_code: '',
      staff_id: '',
      staff_nickname: '',
      staff_list: [],
      action_by: '',
      action_type: '',
      advisor_admin_role: false,
      staff_code_checked: false,
      check_staff_code: true,
      vaild_staff_code: true,
      vaild_member: false,
      role: [],
      permission: [],
      member_no: '',
      selected_history: [],
      history_today: true,
      booking_history: [],
      currentDate: new Date(),
      currentDateFormat: '',
      endDateFormat: '',
      show: false,
      sourceList: 'active',
      filtersourceList: {
        booking_date: '',
        sourceList: 1,
        freetext: '',
        advisor: '',
        checkin: '1',
      },
      cancel_reason_list: [],
      member_type_list: [],
      lang: '',
      eventAction: '',
      eventInfo: [],
      org_room_list: [],
      branch_list: [],
      room_list: [],
      machine_list: [],
      machine_sku_list: [],
      bookingDatedataSource: [],
      checkin_list: [
        {
          id: 'all',
          name: '全部',
        },
        {
          id: '1',
          name: '已Check In',
        },
        {
          id: '-1',
          name: '未Check In',
        },
      ],
      date_list: [],
      advisor_list: [],
      full_advisor_list: [],
      chinese_medicine_list: [],
      nutritionist_list: [],
      tasks: [
        {
          text: '特別項目',
        }
      ],
      special_event_list: [],
      dataSource: [],
      currentTime: '',
      currentTime1: '',
      today_date: '',
      request_text: '',
      update_sync_delete: '0',
      booking_idList: [],
      orderBy: 'advisor',
      orderBy2: 'booking_date',
      orderBy3: 'start_time',
      orderByFM: 'ASC',
      orderByFM2: 'ASC',
      orderByFM3: 'ASC',
      timerdialogcount: 0,
      timer_click: false,
      timer_list: [],
      timer_list_existed: false,
      timerfontcolor: 'success',
      edit_mode: false,
      toasts: [],
    }
  },
  computed: {
    dataSourceList: function () {
      return this.get_dataSourceList(this.filtersourceList.sourceList)
    },
    getField: function () {
      var fields = []
      if(this.filtersourceList.sourceList == 1){
        fields = [
          {
            label: '顧問',
            field: 'advisor',
            sortable: false,
            isKey: false,
            width: "15%",
          },
          {
            label: this.$t('menu.form.member'),
            field: 'member',
            sortable: false,
            isKey: false,
            width: "27%",
          },
          {
            label: '預約日期時間',
            field: 'booking_time',
            sortable: false,
            isKey: false,
            width: "16%",
          },
          {
            label: '已處理',
            field: 'todo',
            sortable: false,
            isKey: false,
            width: "8%",
          },
          {
            label: '備註',
            field: 'todo_remark',
            sortable: false,
            isKey: false,
            width: "34%",
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            isKey: false,
            width: "10%",
          }
        ]
      }else if(this.filtersourceList.sourceList == 2 || this.filtersourceList.sourceList == 3 || this.filtersourceList.sourceList == 4){
        fields = [
          {
            label: '顧問',
            field: 'advisor',
            sortable: false,
            isKey: false,
            width: "25%",
          },
          {
            label: '新會員',
            field: 'new_number',
            sortable: false,
            isKey: false,
            width: "25%",
          },
          {
            label: '舊會員',
            field: 'old_number',
            sortable: false,
            isKey: false,
            width: "25%",
          },
          {
            label: '總數',
            field: 'total_number',
            sortable: false,
            isKey: false,
            width: "25%",
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            isKey: false,
            width: "5%",
          }
        ]
      }
      return fields
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = 'Advisor Showup'
      }
    },
  },
  created() {
    const self = this
    var fd_offset = self.currentDate.getTimezoneOffset()
    var date = new Date(self.currentDate.getTime() - fd_offset * 60 * 1000)
    self.currentDateFormat = date.toISOString().split('T')[0]
    var date_day = new Date().getDay()
    var day_list = ['日', '一', '二', '三', '四', '五', '六']
    self.date_day = day_list[date_day]
    self.today_date = date.toISOString().split('T')[0]
    self.endDateFormat = self.currentDateFormat
    self.branch = this.$route.params.branch
    self.lang = localStorage.getItem('locale')
    setTimeout(function () {
      document.getElementById('confirmModal_open').click()
      setTimeout(function () {
        document.getElementById('staff_code').focus()
      }, 500)
    }, 300)
    setInterval(this.getNow, 1000)
    setInterval(this.check_timer, 10000)
    self.getrealip()
  },
  mounted() {
    const self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
    })
    window.Echo.channel('booking_channel')
    .listen('.booking', (e) => {
      if(self.branch_id == e.message){
        self.need_update = true
      }
    })
  },
  methods: {
    getrealip(){
      const self = this
      var branch_list = []
      this.axios
        .post('/booking/prepare', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          branch_list = response.data.branch_list
          self.axios
          .get('https://record.ankh.com.hk/api/getip', {})
          .then(function (res) {
            const ip = res.data.ip
            branch_list.forEach((item) => {
              if(item.ip_address == ip || item.ip_address2 == ip){
                self.allowed = true
              }
            })
          })
          .catch((error) => { 
            console.error(error)
            self.allowed = true
          })
        })
    },
    getNow: function() {
      const self = this
      const today = new Date();
      const after1hour = new Date();
      after1hour.setHours(after1hour.getHours() + 1)
      const time = today.getHours().toString().padStart(2, '0') + ":" + today.getMinutes().toString().padStart(2, '0')
      const time2 = after1hour.getHours().toString().padStart(2, '0') + ":" + after1hour.getMinutes().toString().padStart(2, '0')
      self.currentTime = time
      self.currentTime1 = time2
      if(self.timerdialogcount > 0){
        self.timerdialogcount--
      }
    },
    getTimeDiff(time, diff){
      const formatTime = time.split(":")[0]*60 + time.split(":")[1]*1
      const current = this.currentTime.split(":")[0]*60 + this.currentTime.split(":")[1]*1
      if(current-formatTime > diff){
        return true
      }
      return false
    },
    get_dataSourceList(type) {
      const self = this
      let list = []
      var temp_list = []
      var count_temp_list = []
      if(type == 1){
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.member_no
        })
        if(this.filtersourceList.advisor){
          list = list.filter(function (item) {
            return item.advisor.includes(self.filtersourceList.advisor.toString())
          })
        }
        if(this.filtersourceList.checkin != 'all'){
          if(this.filtersourceList.checkin == '1'){
            list = list.filter(function (item) {
              return item.checkin_time
            })
          }else{
            list = list.filter(function (item) {
              return !item.checkin_time
            })
          }
        }
        if(this.filtersourceList.booking_date){
          list = list.filter(function (item) {
            return item.booking_date == self.filtersourceList.booking_date
          })
        }
      }else if(type == 2 || type == 3 || type == 4){
        var shop = '2'
        if(type == 2){
          shop = '2'
        }else if(type == 3){
          shop = '1'
        }else if(type == 4){
          shop = '5'
        }
        self.advisor_list = self.full_advisor_list.filter(function (item) {
          return item.shop.includes(shop)
        })
        temp_list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.member_no && item.branch_id == shop
        })
        self.advisor_list.forEach((item) => {
          var new_number = 0
          var new_number_show = 0
          var old_number = 0
          var old_number_show = 0
          count_temp_list = temp_list.filter(function (item2) {
            return item2.advisor.includes(item.id.toString())
          })
          new_number = count_temp_list.filter(function (item3) {
            return item3.new_join == '1'
          }).length
          new_number_show = count_temp_list.filter(function (item3) {
            return item3.new_join == '1' && item3.checkin_time
          }).length
          old_number = count_temp_list.filter(function (item3) {
            return item3.new_join == '0'
          }).length
          old_number_show = count_temp_list.filter(function (item3) {
            return item3.new_join == '0' && item3.checkin_time
          }).length
          var temp_obj = {}
          temp_obj.advisor = [item.id.toString()]
          temp_obj.new_number = new_number
          temp_obj.new_number_show = new_number_show
          temp_obj.old_number = old_number
          temp_obj.old_number_show = old_number_show
          temp_obj.total_number = new_number + old_number
          temp_obj.total_number_show = new_number_show + old_number_show
          list.push(temp_obj)
        })
      }else{
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.member_no
        })
      }
      if(this.filtersourceList.freetext){
        list = list.filter(function (item) {
          return item?.member_no?.toUpperCase().includes(self.filtersourceList.freetext.toUpperCase())
        })
      }
      return list
    },
    initialize() {
      const self = this
      self.special_event_list = [
        {
          id: 1,
          event: '午膳'
        },
        {
          id: 2,
          event: '休假'
        },
        {
          id: 3,
          event: '培訓'
        },
        {
          id: 4,
          event: '會議'
        },
        {
          id: 5,
          event: '其他'
        }
      ]
      this.formData = new FormData()
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/prepare', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.staff_list = response.data.staff_list
        self.org_room_list = JSON.parse(JSON.stringify(response.data.room_list))
        self.room_list = JSON.parse(JSON.stringify(response.data.room_list))
        self.machine_list = JSON.parse(JSON.stringify(response.data.machine_list))
        self.machine_sku_list = JSON.parse(JSON.stringify(response.data.machine_sku_list))
        self.branch_list = response.data.branch_list
        self.cancel_reason_list = response.data.cancel_reason_list
        self.member_type_list = response.data.member_type_list
        var search_branch = response.data.branch_list.filter(function (item) {
          return item.branch == self.branch
        })
        if(search_branch){
          self.branch_id = search_branch[0].id
        }
        var user_role_list = response.data.user_role_list
        self.staff_list.forEach((item) => {
          item.text = item.nickname
          if(item.active == 1){
            if(!self.advisor_admin_role){
              if(item.shop.includes(self.branch_id) && user_role_list.length > 0){
                var advisor = user_role_list.filter(function (role) {
                  return item.id == role.parent_id && role.role == 3 && role.status == 1
                })
                if(advisor.length > 0){
                  self.advisor_list.push(item)
                }
                var chinese_medicine = user_role_list.filter(function (role) {
                  return item.id == role.parent_id && role.role == 7 && role.status == 1
                })
                if(chinese_medicine.length > 0){
                  self.chinese_medicine_list.push(item)
                }
                var nutritionist = user_role_list.filter(function (role) {
                  return item.id == role.parent_id && role.role == 12 && role.status == 1
                })
                if(nutritionist.length > 0){
                  self.nutritionist_list.push(item)
                }
              }
            }else{
              var advisor = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 3 && role.status == 1
              })
              if(advisor.length > 0){
                self.full_advisor_list.push(item)
                self.advisor_list.push(item)
              }
            }
          }
        })
        setTimeout(function () {
          self.get_api()
        }, 500)
      })
    },
    get_api() {
      const self = this
      self.show = true
      self.dataSource = []
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('advisor', self.advisor)
      this.formData.append('start_booking_date', self.currentDateFormat)
      this.formData.append('end_booking_date', self.endDateFormat)
      if(!self.advisor_admin_role){
        this.formData.append('branch', self.branch)
      }
      this.formData.append('history', '1')
      this.formData.append('orderBy', self.orderBy)
      this.formData.append('orderBy2', self.orderBy2)
      this.formData.append('orderBy3', self.orderBy3)
      this.formData.append('orderByFM', self.orderByFM)
      this.formData.append('orderByFM2', self.orderByFM2)
      this.formData.append('orderByFM3', self.orderByFM3)
      this.axios
      .post('/booking/todoList', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.need_update = false
        if(self.version){
          if(self.version != response.data.version){
            self.version_update = true
            setTimeout(function () {
              self.reload()
            }, 5000)
          }
        }else{
          self.version_update = false
          self.version = response.data.version
        }
        self.dataSource = response.data.data
        self.bookingDatedataSource = response.data.data
        if(self.dataSource.length > 0 ){
          self.dataSource.forEach((item, idx, array) => {
            if(item.name == '找不到相關會員編號' && item.created_from != '1'){
              self.member_check(item)
            }
            if(item.member_no && !item.name){
              //self.member_check(item)
            }
            item.bookingDate = item.booking_date
            item.startDate = item.bookingDate+'T'+item.start_time+':00'
            item.endDate = item.bookingDate+'T'+item.end_time+':00'
            item.bookingStartTime = {
              hours: item.start_time.substring(0, 2),
              minutes: item.start_time.substring(3, 5),
              seconds: '00'
            }
            item.bookingEndTime = {
              hours: item.end_time.substring(0, 2),
              minutes: item.end_time.substring(3, 5),
              seconds: '00'
            }
            if(item.arrival_time){
              item.arrivalTime = {
                hours: item.arrival_time.substring(0, 2),
                minutes: item.arrival_time.substring(3, 5),
                seconds: '00'
              }
            }else{
              item.arrivalTime = {
                hours: '00',
                minutes: '00',
                seconds: '0'
              }
            }

            // Advisor array
            var advisor = []
            if(item.advisor){
              var advisor_re = item.advisor
              advisor_re = advisor_re.replace('[', '')
              advisor_re = advisor_re.replace(']', '')
              advisor_re = advisor_re.replace('"', '')
              advisor = advisor_re.split(',')
            }
            item.advisor = advisor

            // Chinese Medicine array
            var chinese_medicine = []
            if(item.chinese_medicine){
              var chinese_medicine_re = item.chinese_medicine
              chinese_medicine_re = chinese_medicine_re.replace('[', '')
              chinese_medicine_re = chinese_medicine_re.replace(']', '')
              chinese_medicine_re = chinese_medicine_re.replace('"', '')
              chinese_medicine = chinese_medicine_re.split(',')
            }
            item.chinese_medicine = chinese_medicine

            // Nutritionist array
            var nutritionist = []
            if(item.nutritionist){
              var nutritionist_re = item.nutritionist
              nutritionist_re = nutritionist_re.replace('[', '')
              nutritionist_re = nutritionist_re.replace(']', '')
              nutritionist_re = nutritionist_re.replace('"', '')
              nutritionist = nutritionist_re.split(',')
            }
            item.nutritionist = nutritionist

            // Machine array
              var machine = []
              if(item.machine){
                var machine_re = item.machine
                machine_re = machine_re.replace('[', '')
                machine_re = machine_re.replace(']', '')
                machine_re = machine_re.replace('"', '')
                machine = machine_re.split(',')
              }
              item.machine = machine

            item.datachange = false
            item.without_therapist = item.without_therapist.toString()

            if (idx === array.length - 1){
              setTimeout(function () {
                self.show = false
              }, 300)
            }
          })
        }else{
          setTimeout(function () {
            self.show = false
          }, 300)
        }
      })
    },
    inline_edit(data){
      data.datachange = true
    },
    submit() {
      const self = this
      if (self.eventInfo.bookingDate.length != 10) {
        var fd_offset = self.eventInfo.bookingDate.getTimezoneOffset()
        var date = new Date(self.eventInfo.bookingDate.getTime() - fd_offset * 60 * 1000)
        self.eventInfo.bookingDate = date.toISOString().split('T')[0]
      }
      self.eventInfo.startDate = self.eventInfo.bookingDate+"T"+self.eventInfo.bookingStartTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingStartTime.minutes.toString().padStart(2, '0')+":00"
      self.eventInfo.endDate = self.eventInfo.bookingDate+"T"+self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')+":00"
      self.eventInfo.arrival_time = self.eventInfo.arrivalTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.arrivalTime.minutes.toString().padStart(2, '0')
      self.eventInfo.status = 1
      self.update_api()
    },
    update_api() {
      const self = this
      self.edit_mode = false
      self.show = true
      if(self.role_check(3)){
        var temp_obj = {}
        temp_obj.id = self.eventInfo.id
        temp_obj.todo = self.eventInfo.advisorTodo
        temp_obj.todo_remark = self.eventInfo.advisorTodoRemark
        this.axios
        .post('/booking/todo', {
          data: temp_obj,
          staff_id: self.staff_id,
          action_by: self.action_by,
        })
        .then(function () {
          self.show = false
          self.toasts.push({
            title: '成功更新',
            content: ''
          })
          //self.get_api()
        })
      }else{
        this.axios
        .post('/booking/update', {
          data: self.eventInfo,
          branch: self.branch,
          staff_id: self.staff_id,
          action_by: self.action_by,
        })
        .then(function () {
          self.show = false
          self.toasts.push({
            title: '成功更新',
            content: ''
          })
          //self.get_api()
        })
      }
    },
    action_request(){
      document.getElementById('requestModal_open').click()
    },
    member_check(info) {
      const self = this
      if (info.member_no) {
        self.vaild_member = true
        //POS API
        this.axios({
        method: 'post',
        url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
        data: {
          find: info.member_no
        },
        headers: {
          'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
          'X-Requested-With': `XMLHttpRequest`
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.result == "success"){
            info.member_no = response.data.member.memberId
            info.name = response.data.member.cardName
            if(!info.gender){
              info.gender = response.data.member.gender
            }
          }else{
            self.vaild_member = false
            info.name = "找不到相關會員編號"
          }
        })
      }
    },
    getlistitem(list, id) {
      const self = this
      var nickname = ""
      if(list == "special_event"){
        var selected = self.special_event_list.find(i => i.id == id)
        if(selected){
          return selected.event
        }
      }else if(list == "room"){
        selected = self.room_list.find(i => i.id == id)
        if(selected){
          return selected.room
        }
      }else if(list == "machine"){
        selected = self.machine_list.find(i => i.id == id)
        if(selected){
          return selected.machine
        }
      }else if(list == "machine_sku"){
        selected = self.machine_sku_list.find(i => i.id == id)
        if(selected){
          return selected.sku
        }
      }else if(list == "advisor"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((advisor, index) => {
            selected = self.advisor_list.find(i => i.id == advisor)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "nutritionist"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((nutritionist, index) => {
            selected = self.nutritionist_list.find(i => i.id == nutritionist)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }
      else if(list == "chinese_medicine"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((chinese_medicine, index) => {
            selected = self.chinese_medicine_list.find(i => i.id == chinese_medicine)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "cancel_reason"){
        selected = self.cancel_reason_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }else if(list == "staff"){
        selected = self.staff_list.find(i => i.id == id)
        if(selected){
          return selected.nickname
        }
      }else if(list == "member_type"){
        selected = self.member_type_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }
    },
    role_check(role) {
      const self = this
      if (self.role.includes(role)) {
        return true
      } else {
        return false
      }
    },
    action_auth(type, data) {
      const self = this
      self.action_type = type
      self.eventInfo = data
      self.staff_code = ''
      data.datachange = false
      setTimeout(function () {
        document.getElementById('confirmModal_open').click()
        setTimeout(function () {
          document.getElementById('staff_code').focus()
        }, 500)
      }, 300)
    },
    staff_code_check() {
      const self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if(response.data.check_ip == false){
              self.allowed = true
            }
            if(self.staff_code_checked == false){
              self.staff_id = ''
              self.role = []
              if (staff_info.length > 0) {
                self.staff_id = staff_info[0]['id']
                self.staff_nickname = staff_info[0]['nickname']
                self.role = response.data.role
                var view_role = self.role.findIndex(el => el == '15')
                var ip_role = self.role.findIndex(el => el == '20')
                var advisor_role = self.role.findIndex(el => el == '3')
                var advisor_admin = self.role.findIndex(el => el == '32')
                var index = self.role.findIndex(el => el == '14')
                if(view_role >= 0 && index >= 0){
                  if(self.allowed == true || ip_role >= 0){
                    self.vaild_staff_code = true
                    self.permission = response.data.permission
                    if(index >= 0){
                      self.staff = 'all'
                    }
                    if(advisor_admin >= 0){
                        self.advisor_admin_role = true
                        self.filtersourceList.sourceList = '2'
                        self.advisor = 'all'
                        var temp_date = new Date(self.currentDateFormat)
                        temp_date.setDate(temp_date.getDate()-30)
                        self.currentDateFormat = temp_date.toISOString().split('T')[0]
                    }else{
                      if(advisor_role >= 0){
                        self.advisor = self.staff_id
                      }else{
                        self.advisor = 'all'
                        var temp_date = new Date(self.currentDateFormat)
                        temp_date.setDate(temp_date.getDate()-7)
                        self.currentDateFormat = temp_date.toISOString().split('T')[0]
                      }
                    }
                    var date1 = new Date(self.currentDateFormat)
                    var date2 = new Date(self.endDateFormat)
                    while (date1 <= date2) {
                      var temp_obj = {}
                      temp_obj.id = new Date(date1).toISOString().split('T')[0]
                      self.date_list.push(temp_obj)
                      date1.setDate(date1.getDate() + 1)
                    }
                    self.filtersourceList.booking_date = self.today_date
                    document.getElementById('confirmModal_close').click()
                    self.staff_code_checked = true
                    self.show = true
                    self.initialize()
                  }else{
                    self.vaild_staff_code = false
                  }
                }else{
                  self.vaild_staff_code = false
                }
              }else {
                self.vaild_staff_code = false
              }
            }else{
              if (staff_info.length > 0) {
                self.action_by = staff_info[0]['id']
                document.getElementById('confirmModal_close').click()
                if(self.action_type == 'submit'){
                  self.submit()
                }
                self.vaild_staff_code = true
              }else {
                self.vaild_staff_code = false
              }
            }
          })
        document.getElementById('staff_code').blur()
      }
    },
    home_page(){
      document.getElementById('confirmModal_close').click()
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
@media screen and (max-width: 1080px) {
  .body {
    font-size: 16px !important;
  }
}

.modal {
  top: -20px !important;
}

.disable-date {
  background-image: repeating-linear-gradient( 135deg, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.2) 4px, transparent 4px, transparent 9px );
}

.genderM {
  border-left: 10px solid #a9e7f3 !important;
  border-top: 10px solid #a9e7f3 !important;
  border-bottom: 10px solid #a9e7f3 !important;
}

.genderF {
  border-left: 10px solid #ffd5df !important;
  border-top: 10px solid #ffd5df !important;
  border-bottom: 10px solid #ffd5df !important;
}

.genderMBG{
  background-color: #a9e7f3 !important;
}

.genderFBG{
  background-color: #ffd5df !important;
}

.newJoin {
  background-color: #ebeb38 !important;
}

.CallTeam {
  background-color: #50d43a;
}

.WTSTeam {
  background-color: #ff9f55;
}

.vtl-tbody-td{
  padding: 0.3rem !important;
}

.fa-whatsapp  {
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 60%,transparent 0);
}

.fa-phone  {
  color: rgb(4, 97, 66);
}

.fa-star  {
  color: #c9c930;
}

.drag {
  box-sizing: border-box;
  padding: 3px 5px;
  margin-bottom: 5px;
}

.offcanvas-width{
  width: 550px !important;
}

.offcanvas-width2{
  width: 700px !important;
}

.offcanvas-width3{
  width: 250px !important;
}

.offcanvas-width4{
  width: 550px !important;
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
.without_therapist {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update2 {
  color: white;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.tab-content {
  padding: 0px !important; 
}

.nav-link {
  color: #525c6a;
}

.nav-tabs {
  border-bottom: 0px solid;
}

.multiselect-dropdown {
  height: 25rem !important;
}

.offcanvas-start {
  z-index: 9999 !important;
}

.vtl-table {
  max-height: calc(100vh - 250px) !important;
}

.toast {
  background-color: #1fb05fd9 !important;
}

.toast-header {
  color: #000000 !important;
}
</style>